import { useState } from "react";

const getImage = async () => {
  return await (await fetch("https://cf_dev_challenge_4.leoorshansky.workers.dev/images/random")).json();
}

const initialImage = getImage();

function App() {

  const [image, setImage] = useState(initialImage);

  const generatePhoto = async () => {
    setImage(await getImage());
  }

  return (
    <div>
      <header style = {{"margin": "auto", "textAlign": "center"}}>
        <h3>Click the button to generate a new random photo!</h3>
        <button onClick={generatePhoto}>Generate</button>
      </header>
      <a href={image.link} style= {{"margin": "auto"}}>
      <img src={image.src} alt={image.alt}></img></a>
    </div>
  );
}

export default App;
